<template>
  <div class="hello container mt-3 mb-5">
    <h4>Shipping Matrix - TEST DRAG AND DROP</h4>

    <div class="bg-light border-sm p-3">
      
      <div class="row">
        <div class="col-md-6">
          <h5 class="lead mt-3">Available</h5>

          <div class="available-items">
            <div 
              v-for="item in items"
              :key="item.id"
              class="p-2 border border-secondary mb-2"
              @drop="onDrop($event, item.id)"
              @dragover.prevent
              @dragenter.prevent
            >
              <div
                class="d-flex align-items-center"
                draggable
                @dragstart="startDrag($event, item)"
                @dragover="onDragOver($event)"
              >
                <b-img src="https://www.fillmurray.com/50/70" fluid></b-img>
                <span class="ml-2">{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <h5 class="lead mt-3">Available</h5>
          <div class="selected-items drop-zone">
            <div 
              v-for="item in selected_items"
              :key="item.id"
              class="p-2 border border-secondary mb-2"
              @drop="onDrop($event, item.id)"
              @dragover="onDragOver($event)"
              @dragenter.prevent
            >
              <div
                class="d-flex align-items-center"
                draggable
                @dragstart="startDrag($event, item)"
              >
                <b-img src="https://www.fillmurray.com/50/70" fluid></b-img>
                <span class="ml-2">{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ShippingMatrix',
  props: {},
  data() {
    return {
      items: [
        {id: 1, text: "1" },
        {id: 2, text: "2" },
        {id: 3, text: "3" },
        {id: 4, text: "4" },
        {id: 5, text: "5" },
      ],
      selected_items: [],
      dragged_over: null,
    };
  },
  computed: {
  },
  methods: {
    startDrag (evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.id)
    },
    onDrop (evt) {
      this.dragged_over = null;

      const itemID = evt.dataTransfer.getData('itemID')
      const item = this.items.find(x => x.id == itemID)
      console.log(item);
      
      // move it
      const from_index = this.items.findIndex( x => x.id == item.id );
      this.items.splice(from_index, 1);
      
      const to_index = this.items.findIndex( x => x.id == this.dragged_over );
      this.items.splice(to_index, 0, item);
    },
    onDragOver (evt) {
      this.dragged_over = parseInt(evt.target.innerText);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.draggable {
  background: #bad0fa;
  border-radius: 4px;
  border: 1px solid #dadada;
  padding: 0.5rem;
}
.drop-zone:drop(active) {
  background: red;
}
</style>
